<template>
  <div class="pt-md-4">
    <!-- <router-link
      to="/numbers"
      class="back-link"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      Numbers
    </router-link> -->

    <h1 class="hidden-sm-and-down dark-text font-weight-semi-bold fz-28 px-1">Callflows</h1>
    <v-card class="hidden-md-and-up py-3 px-2">
      <router-link
        to="/"
        class="back-link px-md-0 ml-md-n1 d-flex align-center"
      >
        <v-icon size="24px" color="#6170C5">
          navigate_before
        </v-icon>
        <h1 class="dark-text font-weight-normal fz-20 font-weight-semi-bold">Callflows</h1>
      </router-link>
    </v-card>
    <div class="d-flex my-2 mx-3 mx-md-0">
      <v-btn
        :to="{ name: 'CreateCallflow' }"
        :style="{ color: isDesktop ? '#637381' : '#A7A5B1' }"
        :class="isDesktop ? 'font-weight-regular' : 'font-weight-medium'"
        text
        class="text-none d-flex align-center px-2"
        height="36px"
      >
        <v-icon size="24px" color="#919EAB" class="mr-1">add</v-icon>
        Create Callflow
      </v-btn>

      <!-- <div class="ml-5">
        <more-action-component :items="['Edit information', 'Remove']"/>
      </div> -->
    </div>

    <v-card
      :loading="loading && !deleting"
      :style="loading ? 'min-height:4px' : ''"
      class="pa-0 overflow-hidden"
    >
      <v-list v-if="callflows.length">
        <template v-for="(callflow, index) in callflows">
          <v-divider
            v-if="index"
            :key="index + Date.now()"
            class="hl-724"
          ></v-divider>

          <v-list-item
            :key="index"
            class="px-4"
          >
            <!--@click="callflow?.did?.length ? openExpand(index) : ''" -->
            <v-list-item-content class="py-2">
              <v-list-item-title
                :style="isDesktop ? 'letter-spacing:-0.5px' : ''"
                :class="{ 'font-weight-regular line-h-28 black-text': isDesktop }"
                class="fz-20 d-flex"
              >
                {{ callflow.name }}
                <v-icon v-if="!callflow.isActive" color="#ED6347" class="ml-1">block</v-icon>
              </v-list-item-title>

              <v-list-item-subtitle
                class="py-1"
                style="color: #637381;"
              >
                <!-- Assigned to {{ callflow.did.length }} numbers -->
              </v-list-item-subtitle>
            </v-list-item-content>

            <!-- <v-list-item-action class="flex-row align-center ma-0">
              <v-btn
                icon
                class="mx-1"
                height="36px"
                @click="expand = !expand"
              >
                <v-icon color="#919EAB">expand_more</v-icon>
              </v-btn>
            </v-list-item-action> -->
            <!-- <v-list-item-action class="flex-row align-center v-list-item__action--normal ma-0">
              <v-tooltip top content-class="v-tooltip--top" v-if="callflow.did.length">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="mx-1"
                    height="36px"
                  >
                    <v-icon color="#919EAB">expand_more</v-icon>
                  </v-btn>
                </template>
                <span>Expand</span>
              </v-tooltip>
            </v-list-item-action> -->
            <v-list-item-action class="flex-row align-center v-list-item__action--hover ma-0">
              <!-- <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="$router.push({ name: 'CreateCallflow', params: { uuid: callflow.uuid } })"
                  >
                    <v-icon color="#919EAB">call_split</v-icon>
                  </v-btn>
                </template>
                <span>Call split</span>
              </v-tooltip> -->

              <!-- <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="openBlockDialog(callflow)"
                  >
                    <v-icon color="#919EAB">{{ callflow.isActive ? 'block' : 'check'}}</v-icon>
                  </v-btn>
                </template>
                <span>{{ callflow.isActive ? 'Block' : 'Unblock'}}</span>
              </v-tooltip> -->

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="$router.push({ name: 'EditCallflow', params: { uuid: callflow.uuid } })"
                  >
                    <v-icon color="#919EAB">edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="openDialog(callflow.uuid)"
                  >
                    <v-icon color="#919EAB">delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>

            </v-list-item-action>
          </v-list-item>
          <!-- <v-expand-transition :key="index + Date.now()">
            <div v-if="callflow.expand">
              <v-divider class="hl-724" />
              <div class="pa-4">
                <div
                  v-for="ubnumber in callflow.did"
                  :key="ubnumber.id"
                  class="text-sm-body-2 assigned-members"
                >
                  {{ ubnumber.didNumber }}
                </div>
              </div>
            </div>
          </v-expand-transition> -->
        </template>
      </v-list>
      <v-card-text v-if="!callflows.length && !loading">Callflow empty!</v-card-text>

      <v-divider/>
      <div v-if="callflowsTotal > itemsPerPage" class="d-flex justify-space-between">
        <div class="ma-5 br-3 grey-button d-flex">
          <template v-for="index in countPage">
            <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0"/>
            <v-btn
              :class="{'grey-button--selected': index === page}"
              text
              tile
              min-width="56"
              class=""
              :key="index"
              @click="pagination(index)"
            >{{ index }}
            </v-btn>
          </template>
        </div>

        <div class="ma-5 br-3 grey-button d-flex">
          <v-btn
            text
            tile
            class="px-0"
            min-width="36"
            width="36px"
            @click="previousPage(page)"
          >
            <v-icon small>
              navigate_before
            </v-icon>
          </v-btn>
          <v-divider vertical/>
          <v-btn
            text
            tile
            min-width="36"
            class="light-text px-0"
            width="36px"
            @click="nextPage(page)"
          >
            <v-icon small>
              navigate_next
            </v-icon>
          </v-btn>
        </div>
      </div>

      <v-dialog
        v-model="deleteConfirmDialog"
        persistent
        :max-width="$vuetify.breakpoint.mdAndUp ? '350' : '260'"
      >
        <v-card>
          <v-card-title class="font-weight-bold">
            Delete
          </v-card-title>

          <v-card-text>
            Are you sure you want to delete callflow?
          </v-card-text>

          <v-card-actions class="pb-5 pt-0 justify-md-end">

            <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
                 class="mx-2">
              <v-btn
                color="secondary"
                outlined
                @click="deleteConfirmDialog = false"
                class="w-100 text-capitalize"
              >
                Cancel
              </v-btn>
            </div>

            <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
                 class="mx-2">
              <v-btn
                depressed
                color="primary"
                @click="remove"
                class="w-100 text-capitalize"
              >
                Delete
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="blockDialog"
        persistent
        :max-width="$vuetify.breakpoint.mdAndUp ? '350' : '260'"
      >
        <v-card>
          <v-card-title class="font-weight-bold">
            {{ toBlockCallflow.isActive ? 'Block' : 'Unblock' }}
          </v-card-title>

          <v-card-text>
            Are you sure you want to {{ toBlockCallflow.isActive ? 'block' : 'unblock' }} callflow?
          </v-card-text>

          <v-card-actions class="pb-5 pt-0 justify-md-end">
            <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'"
                 class="mx-2">
              <v-btn
                color="secondary"
                outlined
                @click="closeBlockDialog()"
                class="w-100 text-capitalize"
              >
                Cancel
              </v-btn>
            </div>

            <div :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 50%'" class="mx-2">
              <v-btn
                depressed
                color="primary"
                @click="block"
                class="w-100 text-capitalize"
              >
                {{ toBlockCallflow.isActive ? 'Block' : 'Unblock' }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <vue-snotify />
    <v-overlay :value="deleting">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// import MoreActionComponent from '@/components/MoreActionComponent'
import { mapActions } from 'vuex'

export default {
  name: 'Callflow',
  // components: {
  //   MoreActionComponent
  // },
  data: () => ({
    deleteConfirmDialog: false,
    loading: false,
    callflows: [],
    callflowsTotal: 0,
    deleteUuid: '',
    itemsPerPage: 10,
    page: 1,
    deleting: false,
    didList: [],
    blockDialog: false,
    toBlockCallflow: {}
  }),
  computed: {
    /* paginationList () {
      return this.callflows.slice((this.page - 1) * this.itemsPerPage, this.itemsPerPage * this.page)
    }, */
    countPage () {
      return Math.ceil(this.callflowsTotal / this.itemsPerPage)
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  created () {
    this.getCallflowsList()
    this.getDidList()
  },
  watch: {
    page () {
      this.getCallflowsList()
    }
  },
  methods: {
    ...mapActions(['getCallflows', 'blockCallflow', 'deleteCallflow', 'didNumbersList']),
    async getCallflowsList (deleting = false) {
      try {
        this.loading = true
        const filter = {
          skip: (this.page - 1) * this.itemsPerPage,
          take: this.itemsPerPage
        }
        const { data } = await this.getCallflows(filter)
        if (!data || !data.payload) return
        if (!data.payload.total) return this.$router.push({ name: 'CreateCallflow' })
        if (!deleting || this.callflowsTotal > data.payload.total) {
          this.callflows = data.payload.items.map(item => ({
            ...item,
            expand: false
          }))
          this.callflowsTotal = data.payload.total
          if (deleting) {
            this.$snotify.notifications = []
            this.$snotify.success('Callflow has been deleted')
            this.deleting = false
          }
          return
        }
        this.deleting = true
        setTimeout(() => {
          this.getCallflowsList(true)
        }, 1000)
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async getDidList () {
      try {
        const { data } = await this.didNumbersList({})
        this.didList = data.didList
      } catch (err) {
        throw new Error(err)
      }
    },
    async remove () {
      try {
        if (!this.deleteUuid) return
        this.deleting = true
        await this.deleteCallflow(this.deleteUuid)
        this.getCallflowsList(true)
      } catch (err) {
        this.$snotify.notifications = []
        let message = err.message
        if (message.includes('assigned did number')) {
          const msg = 'This callflow already has assigned did number '
          const uuid = message.replace(msg, '')
          const did = this.didList.find(e => e.uuid === uuid)
          message = msg + (did ? did.didNumber : '')
        }
        this.$snotify.error(message)
        throw new Error(err)
      } finally {
        this.deleteConfirmDialog = false
        this.deleting = false
        this.deleteUuid = ''
      }
    },
    openBlockDialog (callflow) {
      this.blockDialog = true
      this.toBlockCallflow = callflow
    },
    closeBlockDialog () {
      this.blockDialog = false
      this.toBlockCallflow = {}
    },
    async block () {
      try {
        if (!this.toBlockCallflow.uuid) return
        const { data } = await this.blockCallflow({
          uuid: this.toBlockCallflow.uuid,
          status: !this.toBlockCallflow.isActive
        })
        if (!data.success) return
        this.$snotify.notifications = []
        this.$snotify.success(`Callflow has been ${data.payload.isActive ? 'unblocked' : 'blocked'}`)
        this.closeBlockDialog()
        this.getCallflowsList()
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    openDialog (uuid) {
      this.deleteConfirmDialog = true
      this.deleteUuid = uuid
      this.$emit('closeMenu', false)
    },
    openExpand (idx) {
      this.callflows[idx].expand = !this.callflows[idx].expand
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page !== 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>

<style scoped>
.assigned-members{
  color: #637381;
  font-weight: 400;
  font-family: "SF Pro Text", serif !important;
  line-height: 1.2;
}
</style>
